import "core-js/modules/es.array.concat.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "mini-progress"
  }, [_vm.target !== undefined ? _c('a-tooltip', {
    attrs: {
      "title": '目标值：' + _vm.target + '%'
    }
  }, [_c('div', {
    staticClass: "target",
    style: {
      left: _vm.target + '%'
    }
  }, [_c('span', {
    style: {
      backgroundColor: _vm.color
    }
  }), _c('span', {
    style: {
      backgroundColor: _vm.color
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "wrap",
    style: {
      backgroundColor: _vm.bkcolor
    }
  }, [_c('div', {
    staticClass: "progress",
    style: {
      backgroundColor: _vm.color,
      width: (_vm.percent > 100 ? 100 : _vm.percent) + '%',
      height: _vm.height
    }
  }), _c('span', {
    staticClass: "flag",
    style: "outline-style:".concat(_vm.ieVersion > 0 ? 'none' : 'solid', ";outline-color:").concat(_vm.color + '99', ";color:").concat(_vm.color, ";background-color:").concat(_vm.color, ";height:").concat(_vm.height, ";width:").concat(_vm.height, ";left:calc(").concat(_vm.percent > 100 ? 100 : _vm.percent, "% - 10px);")
  })])], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };