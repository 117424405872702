var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('a-card', {
    staticClass: "chart-card",
    attrs: {
      "body-style": {
        padding: '2px 6px'
      },
      "bordered": false
    }
  }, [_c('div', {
    staticClass: "chart-card-header"
  }, [_c('div', {
    staticClass: "meta"
  }, [_c('span', {
    staticClass: "chart-card-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('span', {
    staticClass: "chart-card-action"
  }, [_vm._t("action")], 2)])]), _c('div', {
    staticClass: "chart-card-content"
  }, [_c('div', {
    staticClass: "content-fix",
    class: _vm.loading ? 'loading' : ''
  }, [_vm._t("default")], 2)])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };