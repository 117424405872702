var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "info"
  }, [_c('a-spin', {
    attrs: {
      "spinning": _vm.spinning
    }
  }, [_c('div', {
    staticClass: "info_content"
  }, [_c('div', {
    staticClass: "info_content_detail"
  }, [_c('info-item', {
    attrs: {
      "title": "联系人："
    }
  }, [_vm._v(" " + _vm._s(_vm.devData.liaison ? _vm.devData.liaison.liaisonName : "无") + " ")]), _c('info-item', {
    attrs: {
      "title": "地址："
    }
  }, [_vm._v(_vm._s(_vm.devData.address))]), _c('info-item', {
    attrs: {
      "title": "设备名称："
    }
  }, [_c('a', {
    attrs: {
      "href": "javascript:;"
    },
    on: {
      "click": _vm.clickName
    }
  }, [_vm._v(_vm._s(_vm.devData.deviceSn))])]), _c('info-item', {
    attrs: {
      "title": "设备型号："
    }
  }, [_vm._v(" " + _vm._s(_vm.devData.deviceModel ? _vm.devData.deviceModel.deviceModelName : "无") + " ")]), _c('info-item', {
    attrs: {
      "title": "设备运行："
    }
  }, [_vm.devStatus === null ? _c('span', {
    staticStyle: {
      "color": "brown"
    }
  }, [_vm._v("离线")]) : _vm.devStatus === '0' ? _c('span', {
    staticStyle: {
      "color": "green"
    }
  }, [_vm._v(" 运行")]) : _vm.devStatus === '1' ? _c('span', {
    staticStyle: {
      "color": "#0095f4"
    }
  }, [_vm._v("开机")]) : _vm.devStatus === '99' ? _c('span', {
    staticStyle: {
      "color": "brown"
    }
  }, [_vm._v("离线")]) : _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("故障")])])], 1), _c('div', {
    staticClass: "info_content_img"
  }, [_c('div', {
    staticClass: "info_content_img_c"
  }, [_c('img', {
    staticClass: "info_content_img_c_i",
    attrs: {
      "src": _vm.Images
    }
  })])])]), _c('div', {
    staticClass: "info_temp"
  }, [_c('canvas', {
    ref: "myCanvas1",
    attrs: {
      "width": "400",
      "height": "30"
    }
  })])])], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };